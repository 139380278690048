import { PureComponent } from 'react';

export default class NotFoundPage extends PureComponent {
  componentDidMount() {
    window.location = '/';
  }

  render() {
    return null;
  }
}
